import { useState } from 'react';

import { Grid } from '@material-ui/core';

import RiskRowItemView from './RiskRowItem.view';

export const RiskRowItem = ({ item, index, showIncompleteOnly, definitionsFields, formProps, removeHandler, copyHandler, hasError }) => {
  const [isBuildingEditOpen, setIsBuildingEditOpen] = useState(false);
  const handleOpenEdit = () => {
    setIsBuildingEditOpen(true);
  };

  const handleCloseEdit = () => {
    setIsBuildingEditOpen(false);
  };

  return isBuildingEditOpen || !showIncompleteOnly || hasError(index) ? (
    <Grid container xs={12} sm={6} md={4} item key={item.id || item?.streetAddress}>
      <RiskRowItemView
        index={index}
        isBuildingEditOpen={isBuildingEditOpen}
        removeHandler={removeHandler}
        copyHandler={copyHandler}
        definitionsFields={definitionsFields}
        formProps={formProps}
        handleOpenEdit={handleOpenEdit}
        handleCloseEdit={handleCloseEdit}
      />
    </Grid>
  ) : null;
};
