import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

// mui
import { Box, Grid, makeStyles, Switch } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';

// app
import { Button, ErrorMessage, LocationAutocomplete, Translate } from 'components';

import * as utils from 'utils';

import { RiskRowItem } from './RiskRowItem';

const useStyles = makeStyles((theme) => ({
  buttonAdding: {
    paddingLeft: '30px!important',
  },
  buttonProgress: {
    position: 'absolute',
    top: 15,
    left: 20,
  },
}));

const RenderObjectCards = ({ fields, removeHandler, copyHandler, formProps, definitionsFields, showIncompleteOnly }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const hasError = (index) => {
    const visibleDefs = definitionsFields.arrayItemDef.filter((f) => f.type !== 'hidden');
    const hasErrors = visibleDefs.some((def) => {
      const isFieldInValid =
        errors &&
        errors[definitionsFields.name] &&
        errors[definitionsFields.name][index] &&
        utils.generic.isValidObject(errors[definitionsFields.name][index], def.name);
      return isFieldInValid;
    });
    return hasErrors;
  };
  return (
    <Grid container spacing={3} data-testid="grid-container-object-cards">
      {fields?.map((item, index) => (
        <RiskRowItem
          key={item.id || item?.streetAddress}
          item={item}
          index={index}
          definitionsFields={definitionsFields}
          formProps={formProps}
          showIncompleteOnly={showIncompleteOnly}
          hasError={hasError}
          removeHandler={removeHandler}
          copyHandler={copyHandler}
        />
      ))}
    </Grid>
  );
};

export const AddRiskRowMultipleView = ({
  isAdding,
  field,
  formProps,
  showIncompleteOnly,
  handlers,
  errors,
  fields,
  showDownloadExcelTemplate,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <LocationAutocomplete
          title={field?.label ? field?.label : "utils.string.t('products.multiLocation.label')"}
          buttonLabel={utils.string.t('app.addLocation')}
          textFieldPlaceholder={utils.string.t('products.multiLocation.placeholder')}
          handleAdd={handlers.handleAddNewObj}
          componentRestrictions={field?.componentRestrictions}
          getDTC
        />
        <Box position="relative" display="flex">
          <Box ml={1.5} mt={2}>
            <Button
              color="primary"
              size="small"
              variant="outlined"
              icon={isAdding ? null : FileCopyIcon}
              text={isAdding ? utils.string.t('app.addingLocations') : utils.string.t('app.pasteFromExcel')}
              disabled={isAdding}
              nestedClasses={isAdding ? { btn: classes.buttonAdding } : {}}
              onClick={() =>
                handlers.launchPasteFromExcelModal({
                  name: field.name,
                  headers: field.arrayItemDef.reduce(
                    (acc, def) => (def.type === 'hidden' ? [...acc] : [...acc, { key: def.name, value: '' }]),
                    []
                  ),
                  handlers: {
                    submit: (data) => {
                      handlers.appendHandler(data, true);
                      handlers.closePasteFromExcelModal();
                    },
                  },
                })
              }
            />
          </Box>
          {showDownloadExcelTemplate ? (
            <Box ml={1.5} mt={2}>
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                icon={GetAppIcon}
                text={utils.string.t('app.downloadExcelTemplate')}
                onClick={() => handlers.handleDownloadExcelTemplate()}
              />
            </Box>
          ) : null}
          {isAdding ? <CircularProgress color="primary" size={16} className={classes.buttonProgress} /> : null}
        </Box>
      </Box>

      {errors[field?.name]?.type === 'required' ? (
        <Box mb={2}>
          <ErrorMessage size="lg" hint={errors[field?.name].message} error={{}} />
        </Box>
      ) : null}

      <Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Box ml={1} mr={0.5}>
            <Translate variant="body2" label="app.showIncompleteOnly" />
          </Box>
          <Switch
            size="small"
            checked={showIncompleteOnly}
            onClick={handlers.toggleIncompleteOnly}
            color="primary"
            data-testid="incompleteLocation-switch"
          />
        </Box>
        <RenderObjectCards
          fields={fields}
          removeHandler={handlers.removeHandler}
          copyHandler={handlers.copyHandler}
          formProps={formProps}
          definitionsFields={field}
          showIncompleteOnly={showIncompleteOnly}
        />
      </Box>
    </>
  );
};

AddRiskRowMultipleView.propTypes = {
  field: PropTypes.object.isRequired,
  cols: PropTypes.array.isRequired,
  validFields: PropTypes.array.isRequired,
  formProps: PropTypes.object.isRequired,
  overflow: PropTypes.bool,
  formatData: PropTypes.string,
  handlers: PropTypes.shape({
    launchPasteFromExcelModal: PropTypes.func.isRequired,
    closePasteFromExcelModal: PropTypes.func.isRequired,
    toggleIncompleteOnly: PropTypes.func,
  }),
};
