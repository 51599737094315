export const RISK_ENDORSEMENT_FIELDS = [
  {
    name: 'comments',
    type: 'TEXT',
    indicative: false,
    display: 'MULTI',
    group: 'COMMENTS',
    label: 'Endorsement Comments',
    gridSize: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12,
    },
  },
];
// Q&B
export const QUOTE_PREMIUM_PRECISION = 6;
export const PRODUCT_STATUS_ERROR = 'ERROR';
export const PRODUCT_STATUS_WARN = 'WARN';
export const PRODUCT_STATUS_OK = 'OK';
export const BARRIER_ISLAND_LIMIT = 3;
export const GOOGLE_LOCATIONS_URL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyD0BTN7ADGlnBiPiqfSZRDMoNXD3kck_k4&libraries=places';
// risks
export const RISK_DEFINITION_GENERAL = 'GENERAL';
export const RISK_DEFINITION_PARTY = 'PARTY';
export const RISK_DEFINITION_EFFECTIVE = 'EFFECTIVE';
export const RISK_STATUS_CANCELLED = 'CANCELLED';
export const RISK_STATUS_BOUND = 'BOUND';
export const RISK_STATUS_BLOCKED = 'BLOCKED';
export const RISK_STATUS_EXPIRED = 'EXPIRED';
export const RISK_QUOTE_STATUS_QUOTING = 'QUOTING';
export const RISK_QUOTE_STATUS_DRAFT = 'DRAFT';
export const RISK_QUOTE_STATUS_QUOTED = 'QUOTED';
export const RISK_QUOTE_STATUS_PENDING = 'PENDING';
export const RISK_QUOTE_STATUS_REFERRED = 'REFERRED';
export const RISK_QUOTE_STATUS_DECLINED = 'DECLINED';
export const RISK_QUOTE_STATUS_BOUND = 'BOUND';
export const RISK_QUOTE_STATUS_NTU = 'NTU';
export const RISK_QUOTE_STATUS_BLOCKED = 'BLOCKED';
export const RISK_QUOTE_STATUS_REJECTED = 'REJECTED';
export const RISK_QUOTE_PREBIND = 'PRE_BIND';
export const RISK_ISSUE_WAITING = 'WAITING';
export const RISK_LOCATIONS_ACCURACY = ['ROOFTOP', 'RANGE_INTERPOLATED', 'GEOMETRIC_CENTER'];
export const RISK_SORT_BY_OPTIONS = [
  { sortBy: 'id', sortDirection: 'desc', label: 'Creation Date' },
  { sortBy: 'products', sortDirection: 'asc', label: 'Product A-Z' },
  { sortBy: 'clientId', sortDirection: 'asc', label: 'Client A-Z' },
  { sortBy: 'inceptionDate', sortDirection: 'desc', label: 'Inception date - Newest' },
  { sortBy: 'inceptionDate', sortDirection: 'asc', label: 'Inception date - Oldest' },
  { sortBy: 'expiryDate', sortDirection: 'desc', label: 'Expiry date - Newest' },
  { sortBy: 'expiryDate', sortDirection: 'asc', label: 'Expiry date - Oldest ' },
];

export const RISK_FILTER_BY_OPTIONS = [
  {
    title: 'Products',
    key: 'products',
    type: 'autocomplete',
  },
  {
    title: 'Clients',
    key: 'clients',
    type: 'autocomplete',
  },
  {
    title: 'Inception Date',
    key: 'inceptionDate',
    type: 'Date',
  },
  {
    title: 'Expiry Date',
    key: 'expiryDate',
    type: 'Date',
  },
];

export const RISK_FILTER_BY_OTIONS_RISK_STATUS = {
  title: 'Risk Status',
  key: 'riskStatus',
  type: 'autocomplete',
  options: [
    { value: RISK_STATUS_BLOCKED, label: 'Blocked', name: 'Blocked' },
    { value: RISK_STATUS_BOUND, label: 'Bound', name: 'Bound' },
    { value: RISK_STATUS_CANCELLED, label: 'Cancelled', name: 'Cancelled' },
    { value: RISK_QUOTE_STATUS_DECLINED, label: 'Declined', name: 'Declined' },
    { value: RISK_STATUS_EXPIRED, label: 'Expired', name: 'Expired' },
    { value: RISK_QUOTE_STATUS_NTU, label: 'NTU', name: 'NTU' },
    { value: RISK_QUOTE_STATUS_QUOTED, label: 'Quoted', name: 'Quoted' },
    { value: RISK_QUOTE_STATUS_REFERRED, label: 'Referred', name: 'Referred' },
    { value: RISK_QUOTE_STATUS_REJECTED, label: 'Rejected', name: 'Rejected' },
  ].sort((a, b) => a.label.localeCompare(b.label)),
};

export const FILTER_MIN_DATE = new Date('01/01/2018');
export const FILTER_MAX_DATE = new Date(new Date().setFullYear(new Date().getFullYear() + 5));
export const ALL_RISKS = 'ALL_RISKS';
export const MY_RISKS = 'MY_RISKS';
export const DRAFT_RISKS = 'DRAFT_RISKS';

export const RISK_DISPLAY_TABLE = 'TABLE';
export const RISK_DISPLAY_CARD = 'CARD';

export const PAGE_SIZE = 12;

// Product templates
export const XLSX_TEMPLATE = {
  TRIA: 'https://edgeassets.blob.core.windows.net/templates/Edge Q&B TRIA Building template.xlsx',
  TRIA_USI: 'https://edgeassets.blob.core.windows.net/templates/Edge Q&B TRIA - USI Building template.xlsx',
  WIND_HAIL_DBB: 'https://edgeassets.blob.core.windows.net/templates/Edge Q&B W&H buildings template.xlsx',
};

// Endorsements
export const ENDORSEMENT_CHANGE_TYPE = {
  DEFAULT: 'DEFAULT',
  CANCELLATION: 'CANCELLATION',
  EXTENSION: 'EXTENSION',
};

export const HIDE_ENDORSEMENT_PRODUCTS = ['TRIA'];
export const ENABLED_ENDORSEMENT_PRODUCTS_PROD = ['TRUCKING'];

// netdown
export const PRODUCT_CONSTRUCTION = 'CONSTRUCTION';
